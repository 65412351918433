@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #77aa77;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
}

.btn {
  background-color: #f88d01;
  border-radius: 100px;
  border-color: white;
  color: white;
  width: 180px;
  padding: 10px 20px;
  text-align: center;
  font-weight: bolder;
  text-decoration: none;
  box-shadow: rgba(219, 146, 43, 0.895) 0 -25px 18px -14px inset,
    rgba(235, 161, 43, 0.755) 0 1px 2px, rgba(231, 157, 53, 0.651) 0 2px 4px,
    rgba(187, 127, 44, 0.25) 0 4px 8px, rgba(187, 142, 44, 0.25) 0 8px 16px,
    rgba(187, 125, 44, 0.25) 0 16px 32px;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn:hover {
  box-shadow: none;
  transform: scale(1.05) rotate(-1deg);
}

input::placeholder {
  color: rgba(29, 28, 28, 0.836);
}
